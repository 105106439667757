<!--
    create by xlb on 2022-08-26 
    文件上传封装

 -->
<template>
    <div class="flex">
        
        <!-- 文件选择触发 -->
        <input id="file" v-show="false" ref="fileRef" type="file"
            :multiple="multiple" @change="fileChange" :accept="accept"
        />

        <!-- 九宫格类型 -->
        <div v-if="type == 'card'" :style="{ width: uploadOption.width * uploadOption.column + uploadOption.interval * uploadOption.column + 10 + 'px' }" 
            class="flex row wrap">
            <!-- 九宫格，每个item的样式 -->
            <div :style="{ width: uploadOption.width+'px', height: uploadOption.height+'px', marginRight: uploadOption.interval+'px', marginBottom: uploadOption.interval+'px', }" 
                class="card-item-view flex center" v-for="(item, index) in fileList" :key="'card'+index">
                
                <!-- 九宫格，图片展示 -->
                <div class="flex" v-if="isImage">
                    <div v-if="filyTypeFunc(item.url ? item.url : item.path) == 'mp4'" class="video-view flex">
                        <video  class="card-item-image" :style="{ width: uploadOption.width+'px', height: uploadOption.height+'px' }" :src="item.url ? item.url : item.path"></video>
                        <i class="el-icon-video-camera-solid video-view-play"></i>
                    </div>
                    <el-image v-else class="card-item-image" :style="{ width: uploadOption.width+'px', height: uploadOption.height+'px' }" :src="imageFormat(item.url ? item.url : item.path)" fit="cover"></el-image>
                </div>
                <!-- 九宫格，其他预览展示 -->
                <div v-else class="card-item-file-view" :style="{ width: uploadOption.width+'px', height: uploadOption.height+'px' }">

                    <div class="card-item-file-bg flex center">
                        <span class="card-item-file-bg-text">{{ item.url ? filyTypeFunc(item.url) : filyTypeFunc(item.path) }}</span>
                    </div>
                    <div class="card-item-file-name flex center">
                        <span>{{ '' + (item.url ? filyNameFunc(item.url) : filyNameFunc(item.path)) }}</span>
                    </div>
                </div>

                <!-- 九宫格，悬浮 上传中 的动画 -->
                <div class="up-file-loading-view-c flex overflow-hidden" 
                    :class="[ item.progress < 100 ? 'animate__animated animate__fadeIn' : 'animate__animated animate__fadeOut' ]"
                >
                    <el-progress :width="uploadOption.width - 20" type="circle" :stroke-width="6" :percentage="item.progress" color="green"></el-progress>
                </div>

                <!-- 九宫格，悬浮操作按钮 -->
                <div class="up-file-item-mode-view flex row center">
                    <el-tooltip class="item" effect="light" content="下载文件" placement="top">
                        <i class="el-icon-download up-mode-icon flex" @click="download(item.url)"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="light" content="预览文件" placement="top">
                        <i class="el-icon-view up-mode-icon flex" @click="download(item.url)"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="light" content="复制文件地址" placement="top">
                        <i class="el-icon-document-copy up-mode-icon flex" @click="copyUrl(item.url)"></i>
                    </el-tooltip>
                    <el-tooltip v-if="isEdit" class="item" effect="light" content="删除文件" placement="top">
                        <i class="el-icon-delete up-mode-icon flex" @click="deleteFile(index)"></i>
                    </el-tooltip>
                </div>
            </div>

            <!-- 上传按钮展示 -->
            <div :style="{ width: uploadOption.width+'px', height: uploadOption.height+'px', marginRight: uploadOption.interval+'px', marginBottom: uploadOption.interval+'px', }" 
                class="upload-btn-view flex center" @click="onChooseFile"
                v-if="fileList.length < maxCount && isEdit"
            >
                <span class="upload-btn-icon">+</span>
                <span class="upload-btn-text">上传文件</span>
            </div>
        </div>

        <!-- 列表类型 -->
        <div v-if="type == 'list'" class="flex">
            <!-- 列表类型，每个item的样式 -->
            <div :style="{ width: uploadOption.width+'px', height: uploadOption.height+'px', marginRight: uploadOption.interval+'px', marginBottom: uploadOption.interval+'px', }" 
                class="list-item-view flex row aCenter" v-for="(item, index) in fileList" :key="'card'+index">
                
                <!-- 列表类型，图片展示 -->
                <div v-if="isImage" class="flex-1 flex row aCenter">
                    <el-image class="list-item-image" :style="{ width: uploadOption.height+'px', height: uploadOption.height+'px' }" :src="imageFormat(item.url ? item.url : item.path)" fit="cover"></el-image>
                    <span class="flex-1 flex list-item-img-hint">{{ '' + (item.url ? filyNameFunc(item.url) : filyNameFunc(item.path)) }}</span>
                </div>
                
                <!-- 列表类型，其他预览展示 -->
                <div v-if="!isImage" class="flex-1 flex row aCenter">
                    <div class="list-item-file-bg flex center" :style="{ width: uploadOption.height+20+'px', height: uploadOption.height+'px' }">
                        <span class="list-item-file-bg-text">{{ item.url ? filyTypeFunc(item.url) : filyTypeFunc(item.path) }}</span>
                    </div>
                    <div class="flex-1 list-item-file-name flex center">
                        <span>{{ '' + (item.url ? filyNameFunc(item.url) : filyNameFunc(item.path)) }}</span>
                    </div>
                </div>

                <!-- 列表类型，悬浮 上传中 的动画 -->
                <div class="up-file-loading-view-l flex center overflow-hidden" 
                    :class="[ item.progress < 100 ? 'animate__animated animate__fadeIn' : 'animate__animated animate__fadeOut' ]"
                >
                    <el-progress :width="uploadOption.height - 20" type="circle" :stroke-width="6" :percentage="item.progress" color="green"></el-progress>
                </div>

                <!-- 列表类型，悬浮操作按钮 -->
                <div class="up-file-item-mode-view flex row center">
                    <el-tooltip class="item" effect="light" content="下载文件" placement="top">
                        <i class="el-icon-download up-mode-icon flex" @click="download(item.url)"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="light" content="预览文件" placement="top">
                        <i class="el-icon-view up-mode-icon flex" @click="download(item.url)"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="light" content="复制文件地址" placement="top">
                        <i class="el-icon-document-copy up-mode-icon flex" @click="copyUrl(item.url)"></i>
                    </el-tooltip>
                    <el-tooltip v-if="isEdit" class="item" effect="light" content="删除文件" placement="top">
                        <i class="el-icon-delete up-mode-icon flex" @click="deleteFile(index)"></i>
                    </el-tooltip>
                </div>
            </div>

            <!-- 上传按钮展示 -->
            <div :style="{ width: uploadOption.width+'px', height: uploadOption.height+'px', marginRight: uploadOption.interval+'px', marginBottom: uploadOption.interval+'px', }" 
                class="upload-btn-view flex center" @click="onChooseFile"
                v-if="fileList.length < maxCount && isEdit"
            >
                <span class="upload-btn-icon">+</span>
                <span class="upload-btn-text">上传文件</span>
            </div>
        </div>

        <!-- 上传文件底部的提示信息，支持插槽自定义 -->
        <span v-if="!customInfo" class="flex info-text">{{ info }}</span>
        <slot v-else name="info"></slot>
    </div>
</template>

<script>
import upload from "@/utils/oss";

export default {
    props: {

        /**
         * 双向绑定数据
         */
        urls: {
            type: String,
            default: "",
        },

        /**
         * 文件上传配置
         */
        uploadOption: {
            type: Object,
            default: () => ({
                width: 120,   // 每个item的宽度
                height: 120,  // 每个item的高度
                column: 3,    // 九宫格使用，分为多少列
                interval: 10, // item之间的间隔
            }),
        },

        /**
         * 文件类型限制
         */
        accept: {
            type: String,
            default: '',
        },
        
        /**
         * 文件类型
         * card = 九宫格 （仅支持图片和视频）
         * list = 列表
         */
        type: {
            type: String,
            default: 'card',
        },

        /**
         * 最大上传数量
         * 默认为1，也就是仅能上传一个文件
         */
        maxCount: {
            type: Number,
            default: 1,
        },
        
        /**
         * 文件大小限制
         * 接收值为 kb
         * 例如限制 128kb就传 -> 128  显示2M就传 -> 1024*2
         * 默认值为2M
         */
        fileSizeLimit: {
            type: Number,
            default: 2048,
        },

        /**
         * 选择文件时是否可以多选
         */
        multiple: {
            type: Boolean,
            default: false,
        },

        /**
         * 上传文件下方提示信息
         */
        info: {
            type: String,
            default: "请上传图片",
        },

        /**
         * 是否开启自定义提示信息
         * slot name=info
         */
        customInfo: {
            type: Boolean,
            default: false,
        },

        /**
         * 预览图是否展示图片
         */
        isImage: {
            type: Boolean,
            default: true,
        },

        /**
         * 是否可以编辑？
         * 默认可以编辑，如果设置成false，则添加按钮和删除按钮被隐藏
         */
        isEdit: {
            type: Boolean,
            default: true,
        },

        /**
         * 特定 - 后台有的图没有前缀，需要特殊处理
         */
        baseUrl: {
            type: String,
            default: "",
        }
    },

    watch: {
        urls: {
            handler(val) {
                this.initFileList(val);
            },
            immediate: true,
        },
    },

    data() {
        return {
            // 已选文件列表
            fileList: [],

            filyTypeFunc: (url) => {
                if(url){
                    if(url.indexOf('.') != -1){
                        let _strs = url.split('.');
                        let _type = _strs[_strs.length - 1];
                        if(_type.length < 5){
                            return _type;
                        }
                    }
                }
                return "附件";
            },
            filyNameFunc: (url) => {
                if(url){
                    if(url.indexOf('/') != -1){
                        let _strs = url.split('/');
                        let _type = _strs[_strs.length - 1];
                        if(_type.length > 0){
                            return _type;
                        }
                    }
                }
                return "附件";
            },
        };
    },

    methods: {

        /**
         * 点击打开文件夹选择文件
         */
        onChooseFile () {
            this.$refs.fileRef.dispatchEvent(new MouseEvent("click"));
        },

        /**
         * 双向绑定，初始化列表数据
         */
        initFileList (val) {
            if(val){
                let _list = val.split(',');
                this.fileList = _list.map(m => ({
                    file: null,      // 本地文件路径
                    name: '', // 本地文件名称
                    size: '', // 本地文件大小
                    type: '', // 本地文件类型
                    path: '', // 本地路径
                    url: m,      // 线上地址
                    progress: 100,  // 上传进度
                }));
            }else{
                this.fileList = [];
            }
        },

        /**
         * 根据file文件获取本地路径
         * @param {*} file 
         */
        getFileURL (file) {
            let url = '';
            if (window.createObjectURL != undefined) { 
                // basic
                url = window.createObjectURL(file) ;
            } else if (window.webkitURL != undefined) { 
                // webkit or chrome
                url = window.webkitURL.createObjectURL(file) ;
            } else if (window.URL != undefined) { 
                // mozilla(firefox)
                url = window.URL.createObjectURL(file) ;
            }
            return url;
        },

        /**
         * 选择回调，文件变化
         * @param {*} event
         */
        async fileChange(event) {
            let _files = Array.from(event.target.files);
            // console.log(_files);
            // 验证是否是数组
            if(Array.isArray(_files) && _files.length > 0){
                // 数据格式转换
                _files = _files.map(m => ({
                    file: m,      // 本地文件路径
                    name: m.name, // 本地文件名称
                    size: m.size, // 本地文件大小
                    type: m.type, // 本地文件类型
                    path: this.getFileURL(m), // 本地路径
                    url: '',      // 线上地址
                    progress: 0,  // 上传进度
                }))
                // 数量验证 - 仅未超过最大数量才可以继续进行操作，否了退出选择
                if(_files.length + this.fileList.length <= this.maxCount){
                    // 对数据进行赋值
                    this.fileList = this.fileList.concat(_files);
                    this.testUpload(this.fileList, 0)
                    // 文件循环依次执行上传
                    // for (let i = 0; i < this.fileList.length; i++) {
                    //     let fileItem = this.fileList[i];
                    //     // 直接对整个列表进行循环上传，上传好的不用再次上传
                    //     if(!fileItem.url){
                    //         // console.log(fileItem.size / 1024);
                    //         // 验证每个文件的大小是否超过限制 - fileItem.size 为 b
                    //         if(fileItem.size > this.fileSizeLimit * 1024){
                    //             return this.$message({ type: 'error', message: '文件大小超过限制' });
                    //         }

                    //         // 所有验证都已经通过，开始执行上传操作
                    //         let self = this;
                    //         let url = await this.uploadCosFiles(fileItem.file, (e) => {
                    //             console.log('上传完成')
                    //             self.onProgressFiles(fileItem, i, e);
                    //         }).catch(() => {
                    //             return this.$message({ type: 'error', message: '文件上传失败！' });
                    //         });
                    //         this.updataFileList(fileItem, i, url);
                    //     }
                    // }
                }else{
                    return this.$message({ type: 'error', message: '文件最大选择数量超过限制' });
                }
                // 使用完成之后需要把file的value设置为空，否则重复选择一个文件将不会执行选择回调
                this.$refs.fileRef.value = null;
            }
        },

        /**
         * 测试方法 - 延时上传多图，因为oss有每秒钟调用接口次数的限制。目前每张图片上传间隔暂定为300ms
         */
        async testUpload (list = [], i = 0) {
            if(list.length > i){
                let fileItem = list[i];
                // 直接对整个列表进行循环上传，上传好的不用再次上传
                if(!fileItem.url){
                    // console.log(fileItem.size / 1024);
                    // 验证每个文件的大小是否超过限制 - fileItem.size 为 b
                    if(fileItem.size > this.fileSizeLimit * 1024){
                        return this.$message({ type: 'error', message: '文件大小超过限制' });
                    }

                    // 所有验证都已经通过，开始执行上传操作
                    let self = this;
                    let url = await this.uploadCosFiles(fileItem.file, (e) => {
                        // console.log('上传完成')
                        self.onProgressFiles(fileItem, i, e);
                    }).catch(() => {
                        return this.$message({ type: 'error', message: '文件上传失败！' });
                    });
                    this.updataFileList(fileItem, i, url);
                    setTimeout(() => {
                        this.testUpload(list, ++i)
                    }, 300);
                }else{
                    this.testUpload(list, ++i)
                }
            }
        },

        /**
         * 更新上传的进度，只变更 列表里的进度字段
         * @param {*} obj
         * @param {*} e
         */
        onProgressFiles(obj, i, e) {
            // obj.progress = parseInt(e.percent * 100);
            obj.progress = 100;
            this.$set(this.fileList, i, obj);
        },

        /**
         * 上传成功后更新url数据
         * @param {*} obj
         * @param {*} url
         */
        updataFileList(obj, i, url) {
            obj.url = url;
            this.$set(this.fileList, i, obj);
            // 更新数据
            this.updataUrl();
        },

        /**
         * 下载文件
         */
        download(url) {
            window.open(url);
        },

        /**
         * 复制url地址
         * @param {*} url 
         */
        async copyUrl(url) {
            if (url) {
                this.$clipboard(url);
                this.$message.success("复制成功！");
            } else {
                this.$message.success("复制失败，没有地址！");
            }
        },

        /**
         * 删除文件
         * @param {*} obj
         */
        deleteFile(i) {
            this.fileList = this.fileList.filter((item, index) => index != i);
            // 更新数据
            this.updataUrl();
        },

        /**
         * 文件上传
         * @param {*} file 
         * @param {*} onProgress 
         */
        async uploadCosFiles(file, onProgress) {
            // let that = this;
            // let url = await cosUpload(file, "system", (e) => {
            //     // console.log('上传进度=', e);
            //     // {loaded: 0, total: 80872, speed: 0, percent: 0}
            //     // {loaded: 80872, total: 80872, speed: 75159.85, percent: 1}
            //     onProgress(e);
            // });
            let url = await upload(file);
            onProgress({});
            return url;
        },

        /**
         * 更新数据在这里执行
         */
        updataUrl () {
            let urls = this.fileList.map(m => m.url).join(',');
            this.$emit("update:urls", urls);
        },

        // 这个是特殊用途的，一般不要开启
        imageFormat (src) {
            if(this.baseUrl){
                if(src.indexOf('aliyuncs.com') != -1){
                    return src
                }
                return this.baseUrl + src
            }
            return src
        }
    },
};
</script>

<style lang="scss" scoped>
    
    .upload-btn-view {
        width: 120px;
        height: 120px;
        border: 1px dashed #DFDFDF;
        border-radius: 7px;
        cursor: pointer;
    }
    .upload-btn-view:hover {
        border: 1px dashed #409effbb;
        span {
            color: #409eff;
        }
    }
    .upload-btn-icon {
        font-size: 23px;
        color: #999;
    }
    .upload-btn-text {
        font-size: 14px;
        color: #999;
    }
    .card-item-view {
        position: relative;
    }
    .card-item-view:hover {
        .up-file-item-mode-view {
            display: flex;
            justify-content: space-around;
        }
    }
    .up-file-item-mode-view {
        display: none;
        width: 100%;
        height: 100%;
        background-color: #00000077;
        border-radius: 7px;
        position: absolute;
        top: 0;
        left: 0;
    }
    .card-item-image {
        border-radius: 7px;
        background-color: #F1F1F1;
    }
    .card-item-file-view {
        border-radius: 7px;
        background-color: #F1F1F1;
        position: relative;

        .card-item-file-bg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        .card-item-file-bg-text {
            font-size: 60px;
            color: #CDCDCD;
            font-weight: bold;
        }
    }
    .card-item-file-name {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 14px;
        color: #333333AA;
        padding: 12px;
        line-height: 16px;
        font-weight: bold;
    }
    .up-file-loading-view-c {
        width: 100%;
        height: 100%;
        background-color: #00000066;
        border-radius: 7px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding-left: 10px;
        padding-top: 10px;
        ::v-deep .el-progress__text {
            color: white;
            font-size: 16px;
            font-weight: bold;
        }
    }
    .up-mode-icon {
        font-size: 16px;
        color: #ffffff;
    }
    .info-text {
        font-size: 12px;
        color: #999;
    }


    .list-item-view {
        position: relative;
        background-color: #FFFFFF;
        border-radius: 7px;
        box-sizing: border-box;
        border: 1px solid #EFEFEF;
    }
    .list-item-view:hover {
        .up-file-item-mode-view {
            display: flex;
            justify-content: space-around;
        }
    }
    .up-file-loading-view-l {
        width: 100%;
        height: 100%;
        background-color: #00000066;
        border-radius: 7px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding-left: 0;
        padding-top: 0;
        box-sizing: border-box;
        ::v-deep .el-progress__text {
            color: white;
            font-size: 16px;
            font-weight: bold;
        }
    }
    .list-item-image {
        border-radius: 7px;
        background-color: #F1F1F1;
    }
    .list-item-img-hint {
        font-size: 14px;
        color: #121212;
        line-height: 16px;
        font-weight: bold;
        box-sizing: border-box;
        padding: 0 12px;
    }
    .list-item-file-view {
        border-radius: 7px;
        background-color: #F1F1F1;
        position: relative;
    }
    .list-item-file-bg {
        // position: absolute;
        // top: 0;
        // left: 0;
        background-color: #F1F1F1;
        // border-radius: 7px;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
    }
    .list-item-file-bg-text {
        font-size: 37px;
        color: #CDCDCD;
        font-weight: bold;
        line-height: 37px;
        margin-top: -3px;
    }
    .list-item-file-name {
        font-size: 14px;
        color: #333333AA;
        padding: 12px;
        line-height: 16px;
        font-weight: bold;
    }
    .video-view {
        position: relative;
        .video-view-play {
            position: absolute;
            top: 43%;
            left: 43%;
            color: #FFFFFFCC;
            font-size: 25px;
        }
    }
</style>