import LayoutMain from "@/layout/layout-main";

const routePer = '/home'

const homeRouter = {
	path: '/',
	component: LayoutMain,
	redirect: `/`,
	children: [
		{
			path: `/`,
			component: () => import('@/views/home'),
			name: 'Home',
			meta: {
				title: '首页'
			}
		},
		{
			path: `${routePer}/business`,
			component: () => import('@/views/business'),
			name: 'BusinessList',
			meta: {
				title: '商机大厅'
			}
		},
		{
			path: `${routePer}/businessDetail`,
			component: () => import('@/views/businessDetail'),
			name: 'BusinessDetail',
			meta: {
				title: '商机详情'
			}
		},
		{
			path: `${routePer}/send`,
			component: () => import('@/views/send'),
			name: 'SendBusiness',
			meta: {
				title: '发布商机'
			}
		},
		{
			path: `${routePer}/about`,
			component: () => import('@/views/about'),
			name: 'About',
			meta: {
				title: '关于我们'
			}
		},
		{
			path: `${routePer}/linyiList`,
			component: () => import('@/views/linyi/list'),
			name: 'LinyiList',
			meta: {
				title: '临沂文旅'
			}
		},
		{
			path: `${routePer}/linyiDetail`,
			component: () => import('@/views/linyi/detail'),
			name: 'LinyiDetail',
			meta: {
				title: '临沂文旅'
			}
		},
		{
			path: `${routePer}/taxationList`,
			component: () => import('@/views/taxation/list'),
			name: 'TaxationList',
			meta: {
				title: '财税资讯'
			}
		},
		{
			path: `${routePer}/taxationDetail`,
			component: () => import('@/views/taxation/detail'),
			name: 'TaxationDetail',
			meta: {
				title: '财税资讯'
			}
		}
	]
}

export default homeRouter;