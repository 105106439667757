export default {
	data() {
		return {
            
		}
	},

	computed: {
		// pageHeight() {
        //     console.log('document.body.clientHeight', document.body.clientHeight)
		// 	return document.body.clientHeight;
		// },
	},

    created () {
        console.log('this.$rouer.path = ', this.$route.path)
    },

	mounted() {
        
	},

	methods: {

		onBack() {
			this.$router.push({
				path: '/'
			})
		},

        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        // handleUserClick (e) {
        //     console.log('222', e)
        // },
        onUserCommand (e) {
            // console.log('111', e)
            if(e == 'a'){
                // 退出登录
                this.onOutLogin()
            }
        },
        onOutLogin () {
            console.log('退出登录')
			this.$store.dispatch('outLogin').then(() => {
				this.$router.push('/')
			})
        },
	}
}