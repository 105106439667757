import request from '@/utils/request';

/**
 * 登录(废弃)
 */
export function useToken(data) {
	// return request.post('api/login/getToken', data)
	return request.post('api/login/pcLogin', data)
}

/**
 * 静默登录（废弃）
 * @param uid
 * @returns {void|*}
 */
export function defaultLogin(uid) {
	return request.post('api/login/getToken', {uid}, {
		isToken: true
	})
}

// 产品分类

/**
 * 产品分类
 */
export function merchantCategory(data) {
	return request.post('api/merchantCategory/index', data)
}

/**
 * 产品分类 - 添加
 */
export function merchantCategoryAdd(data) {
	return request.post('api/merchantCategory/add', data)
}
/**
 * 产品分类 - 编辑
 */
export function merchantCategoryEdit(data) {
	return request.post('api/merchantCategory/edit', data)
}
/**
 * 产品分类 - 删除
 */
export function merchantCategoryDelete(data) {
	return request.post('api/merchantCategory/delete', data)
}

// 产品 

/**
 * 产品 - 列表
 */
export function merchantProduct(data) {
	return request.post('api/merchantProduct/index', data)
}
/**
 * 产品 - 添加
 */
export function merchantProductAdd(data) {
	return request.post('api/merchantProduct/add', data)
}
/**
 * 产品 - 编辑
 */
export function merchantProductEdit(data) {
	return request.post('api/merchantProduct/edit', data)
}
/**
 * 产品 - 删除
 */
export function merchantProductDel(data) {
	return request.post('api/merchantProduct/delete', data)
}

// 留言

/**
 * 留言 - 列表
 */
export function merchantMessage(data) {
	return request.post('api/MerchantMessage/pcIndex', data)
}

// 逛全国

/**
 * 逛全国 - 分类
 */
export function countryCategory(data) {
	return request.post('api/CountryCategory/index', data)
}

/**
 * 逛全国 - 店铺列表
 */
export function countryStore(data) {
	return request.post('api/CountryStore/index', data)
}

/**
 * 逛全国 - 店铺列表PC
 */
export function countryStorePc(data) {
	return request.post('api/CountryStore/pcIndex', data)
}

/**
 * 已开发客户 -- 列表
 * @param data
 * @returns {void|*}
 */
export function getOpenedCustomerListApi(data) {
	return request.post('api/MerchantCountry/index', data)
}

/**
 * 已开发客户 -- 备注
 * @param data
 * @returns {void|*}
 */
export function remarkSubmitApi(data) {
	return request.post('api/MerchantCountry/remark', data)
}

/**
 * 已开发客户 -- 标记
 * @param data
 * @returns {void|*}
 */
export function badgeSubmitApi(data) {
	return request.post('api/MerchantCountry/sign', data)
}

/**
 * 逛全国 - 已沟通
 * @param id
 * @returns {void|*}
 */
export function changeCountryStoreStatusApi(id) {
	return request.post('api/CountryStore/develop', {id})
}

// 我发布的商机
export function boList(data) {
	return request.post('api/user/boList', data)
}

// /merchant/bo/category 商机分类
export function boCategory(data) {
	return request.post('merchant/bo/category', data)
}

// 商机行业 merchant/bo/industry
export function boIndustry(data) {
	return request.post('api/category/getAll', data)
}

// 我关注的市场 api/user/marketFollow
export function marketFollow(data) {
	return request.post('api/user/marketFollow', data)
}

// 我关注的活动 /api/user/follow
export function myFollow(data) {
	return request.post('api/user/follow', data)
}

// 我关注的商家 api/user/merchantFollow
export function merchantFollow(data) {
	return request.post('api/user/merchantFollow', data)
}

// 我的访客 api/merchant/visitorList
export function visitorList(data) {
	return request.post('api/merchant/pcVisitorList', data)
}

// 我的足迹api/user/footMark
export function footMark(data) {
	return request.post('api/user/footMark', data)
}
export function footMarkPC(data) {
	return request.post('api/user/pcFootMark', data)
}

// 商家名片夹 api/merchant/cardList
export function cardList(data) {
	return request.post('api/merchant/cardList', data)
}

// 获取视频 /api/merchant/video
export function merchantVideo(data) {
	return request.post('api/merchant/video', data)
}
// 获取视频 detail
export function merchantVideoDetail(data) {
	return request.post('api/merchant/detail', data)
}

// 获取旺铺详情 detail
export function merchantDetail(data) {
	return request.post('api/merchant/detail', data)
}

// 开通旺铺 /api/merchant/open
export function merchantOpen(data) {
	return request.post('api/merchant/open', data)
}

// 获取店铺入驻类型
export function merchantTypeListApi() {
	return request.post('api/merchantType/index')
}

/**
 * 获取店铺部分类型
 * @returns {void|*}
 */
export function merchantOtherTypeListApi() {
	return request.post('api/merchantType/partList')
}

// 修改信息 旺铺编辑 /api/merchant/edit
export function merchantEdit(data) {
	return request.post('api/merchant/edit', data)
}

// 市场列表 api/market/index
export function marketList(data) {
	return request.post('api/market/index', data)
}

// 获取省市县 api/area/getArea
export function getArea(data) {
	return request.post('api/area/getArea', data)
}

// 查看手机号
export function getMobile(data) {
	return request.post('api/CountryStore/getMobile', data)
}

// 海外商机 -- 列表
export function getOverseas(data) {
	return request.post('api/article/overseas', data)
}