import request from "@/utils/request";

/**
 * 微信登录
 * @param code
 * @returns {void|*}
 */
export function wxLogin(code) {
	return request.post('api/login/pcWxLogin', {code})
}

/**
 * 账号密码登录
 * @param data
 * @returns {void|*}
 */
export function accountLogin(data) {
	return request.post("api/login/passwordLogin", data);
}

/**
 * 验证码登录
 * @param data
 * @returns {void|*}
 */
export function codeLogin(data) {
	return request.post("api/login/smsLogin", data);
}

/**
 * 注册
 * @param data
 * @returns {void|*}
 */
export function register(data) {
	return request.post("api/login/pcRegister", data);
}

/**
 * 完善用户信息
 * @param data
 * @returns {void|*}
 */
export function updateUserApi(data) {
	return request.post("api/login/addPcWxUserInfo", data);
}